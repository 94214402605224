import React, { useState } from "react";
import {
  Stack,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Checkbox,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { Description } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  downloadPlotFile,
  ExportType,
  EXPORT_TYPE_INFO,
} from "../../../utils/requestUtils";
import { PlotRequest } from "../../../types/plot_requests/plot_request_interfaces";
import { useUserReport } from "../../../../../common/contexts/UserReportContext";
import {
  RAMFormControl,
  RAMTextField,
} from "../../../../../resources/CustomInputs";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import SettingsIcon from "@mui/icons-material/Settings";
import { usePlotContext } from "../../../contexts/PlotContext";
import {
  MetricMethods,
  PerformanceTableSettings,
} from "../../../types/plot_requests/performance_table";
import { DataConfig } from "../../../types/data_config/data_config_interfaces";
import { BaseTheme } from "../../../types/plot_theme/plot_theme_interfaces";
interface QuickExportProps {
  plotRequest: PlotRequest;
}

type exportTarget = "plot" | "perf_table";

const QuickExport: React.FC<QuickExportProps> = ({ plotRequest }) => {
  const { openErrorPrompt } = useUserReport();
  const { plot, performanceTable } = usePlotContext();
  const IMAGE_DIMENSIONS = {
    plot: {
      manual: { width: 2400, height: 1000 },
      word: { width: 2000, height: 1000 },
      powerpoint: { width: 2400, height: 1000 },
    },
    perf_table: {
      manual: { width: 700, height: undefined },
      word: {
        width: 500 + 100 * plot.config.data_config.portfolios.length,
        height: undefined,
      },
      powerpoint: { width: 1000, height: undefined },
    },
  };
  const { getAccessTokenSilently } = useAuth0();
  const [imageWidth, setImageWidth] = useState<number>(
    IMAGE_DIMENSIONS.plot.manual.width,
  );
  const [imageHeight, setImageHeight] = useState<number | undefined>(
    IMAGE_DIMENSIONS.plot.manual.height,
  );

  const [exportTarget, setExportTarget] = useState<exportTarget>("plot");
  const [selectedFileType, setSelectedFileType] = useState<ExportType>("png");

  const [exportMethod, setExportMethod] = useState<"image" | "spreadsheet">(
    "image",
  );
  const [exportOption, setExportOption] = useState<
    "manual" | "word" | "powerpoint"
  >("manual");

  const applyDimensions = (
    plotRequest:
      | PlotRequest
      | {
          theme: BaseTheme;
          plot_type: "perf_table";
          settings: PerformanceTableSettings;
          metrics: MetricMethods[];
          use_excess_returns: string[];
          data_config: DataConfig;
        },
  ) => {
    if ("settings" in plotRequest && "figsize" in plotRequest.settings) {
      if (imageHeight === undefined) {
        throw new Error();
      }
      plotRequest.settings.figsize = [imageWidth, imageHeight];
    }
    if ("settings" in plotRequest) {
      if (
        "plot_type" in plotRequest &&
        plotRequest.plot_type === "perf_table"
      ) {
        const settings = plotRequest.settings as PerformanceTableSettings;
        settings.row_height = imageHeight;
        settings.table_width = imageWidth;
      }
    }

    return plotRequest;
  };

  const getPlotForm = () => {
    if (exportTarget === "plot") {
      return plot.config;
    } else {
      const config = {
        ...performanceTable.config,
        theme: plot.config.theme,
        data_config: plot.config.data_config,
      };
      return config;
    }
  };

  const downloadPlot = async (fileType: ExportType) => {
    let form = getPlotForm();
    const token = await getAccessTokenSilently();
    form = applyDimensions(form);
    const response = await downloadPlotFile(form, fileType, token);
    if (response.isSuccess) {
      const plotFile = response.body;
      const downloadUrl = window.URL.createObjectURL(plotFile);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `plot.${fileType}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else {
      const warning = "An error occurred while downloading the plot.";
      const slackMessage = `The Plotting App Failed to download a plot`;
      openErrorPrompt(warning, slackMessage);
    }
  };

  const handleExportOptionChange = (
    option: "manual" | "word" | "powerpoint",
  ) => {
    setExportOption(option);
    const presets = IMAGE_DIMENSIONS[exportTarget];
    if (option !== "manual") {
      setImageWidth(presets[option].width);
      setImageHeight(presets[option].height);
    }
  };

  const isDownloadEnabled = () => {
    if (exportTarget === "perf_table") {
      return imageWidth && selectedFileType;
    } else if (exportMethod === "image") {
      return imageWidth && imageHeight && selectedFileType;
    } else {
      return true;
    }
  };

  const handleExportTargetChange = (target: exportTarget) => {
    setExportTarget(target);
    const height = IMAGE_DIMENSIONS[target][exportOption].height;
    const width = IMAGE_DIMENSIONS[target][exportOption].width;
    setImageHeight(height);
    setImageWidth(width);
  };

  return (
    <Stack gap={2}>
      <Box padding={2} bgcolor="#221d25">
        <RAMFormControl fullWidth>
          <InputLabel>Export Target</InputLabel>
          <Select
            label="Export Target"
            value={exportTarget}
            onChange={(e) =>
              handleExportTargetChange(e.target.value as "plot" | "perf_table")
            }
          >
            <MenuItem value="plot">Plot</MenuItem>
            <MenuItem value="perf_table">Performance Table</MenuItem>
          </Select>
        </RAMFormControl>
      </Box>
      <Stack gap={1} sx={{ bgcolor: "#221d25", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "divider",
            width: "fit-content",
          }}
        >
          <Checkbox
            size="small"
            checked={exportMethod === "image"}
            onChange={() => {
              setSelectedFileType("png");
              setExportMethod("image");
            }}
          />
          <Typography variant="body2" color="textSecondary" sx={{ pr: 2 }}>
            Export as Image
          </Typography>
        </Box>

        <Stack direction="row" spacing={2}>
          <Tooltip title="Word">
            <IconButton
              color={exportOption === "word" ? "primary" : "default"}
              onClick={() => handleExportOptionChange("word")}
            >
              <Description sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="PowerPoint">
            <IconButton
              color={exportOption === "powerpoint" ? "primary" : "default"}
              onClick={() => handleExportOptionChange("powerpoint")}
            >
              <SlideshowIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manual">
            <IconButton
              color={exportOption === "manual" ? "primary" : "default"}
              onClick={() => handleExportOptionChange("manual")}
            >
              <SettingsIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="row" spacing={2}>
          <RAMTextField
            label={exportTarget === "perf_table" ? "Table Width" : "Width"}
            type="number"
            value={imageWidth}
            onChange={(e) => setImageWidth(Number(e.target.value))}
            fullWidth
            disabled={exportOption !== "manual" || exportMethod !== "image"}
          />
          {exportTarget !== "perf_table" && (
            <RAMTextField
              label="Height"
              type="number"
              value={imageHeight}
              onChange={(e) => setImageHeight(Number(e.target.value))}
              fullWidth
              disabled={exportOption !== "manual" || exportMethod !== "image"}
            />
          )}
          <RAMFormControl fullWidth>
            <InputLabel>File Type</InputLabel>
            <Select
              label="File Type"
              value={selectedFileType}
              onChange={(e) =>
                setSelectedFileType(e.target.value as ExportType)
              }
              disabled={exportMethod !== "image"}
            >
              {(Object.keys(EXPORT_TYPE_INFO) as ExportType[])
                .filter((fileType) => fileType !== "xlsx")
                .map((fileType) => (
                  <MenuItem key={fileType} value={fileType}>
                    {EXPORT_TYPE_INFO[fileType].name}
                  </MenuItem>
                ))}
            </Select>
          </RAMFormControl>
        </Stack>
      </Stack>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "inherit",
          bgcolor: "#221d25",
          p: 1,
        }}
      >
        <Checkbox
          size="small"
          checked={exportMethod === "spreadsheet"}
          onChange={() => {
            setSelectedFileType("xlsx");
            setExportMethod("spreadsheet");
          }}
        />
        <Typography variant="body2" color="textSecondary" sx={{ pr: 2 }}>
          Export as Spreadsheet
        </Typography>
      </Box>
      <Button
        onClick={() =>
          downloadPlot(exportMethod === "image" ? selectedFileType : "xlsx")
        }
        variant="contained"
        fullWidth
        disabled={!isDownloadEnabled()}
      >
        Download
      </Button>
    </Stack>
  );
};

export default QuickExport;
