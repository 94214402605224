import React, { useEffect } from "react";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { RAMTextField } from "../../../../../../../resources/CustomInputs";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import CheckIcon from "@mui/icons-material/Check";

interface DateRange {
  start_date: string;
  end_date: string;
}

interface EnterDateProps {
  defaultDate?: DateRange | string;
  variant: "button" | "text";
  label?: string;
}

const EnterDate: React.FC<EnterDateProps> = ({
  variant,
  defaultDate,
  label,
}) => {
  const [error, setError] = React.useState<boolean>(false);
  const formatDate = (date: string) => date.split("T")[0];
  const { plot } = usePlotContext();

  const dateRangeToStr = (dateRange: DateRange) => {
    let startDate = formatDate(dateRange.start_date);
    let endDate = formatDate(dateRange.end_date);

    // Remove -01-01 or -01 suffixes
    startDate = startDate.replace(/-01-01$/, "").replace(/-01$/, "");
    endDate = endDate.replace(/-01-01$/, "").replace(/-01$/, "");

    return `${startDate}::${endDate}`;
  };

  // Function to convert DateRange to string format
  const processDefaultDate = (defaultDate: DateRange | string): string => {
    if (typeof defaultDate === "string") {
      return defaultDate;
    } else {
      return dateRangeToStr(defaultDate);
    }
  };

  const [inputValue, setInputValue] = React.useState<string>(() =>
    defaultDate
      ? processDefaultDate(defaultDate)
      : processDefaultDate({
          start_date: plot.config.data_config.start_date,
          end_date: plot.config.data_config.end_date,
        }),
  );

  useEffect(() => {
    const newDateRange = {
      start_date: plot.config.data_config.start_date,
      end_date: plot.config.data_config.end_date,
    };
    setInputValue(processDefaultDate(newDateRange));
  }, [plot.config.data_config.start_date, plot.config.data_config.end_date]);

  const submitDateRange = (startDate: string, endDate: string) => {
    plot.update({
      data_config: {
        ...plot.config.data_config,
        start_date: startDate,
        end_date: endDate,
      },
    });
  };

  function calculateStartDateFromShorthand(shorthand: string): string {
    const today = new Date();
    let startDate = new Date();

    if (shorthand === "YTD") {
      startDate = new Date(today.getFullYear(), 0, 1); // Start of the current year
    } else {
      const value = parseInt(shorthand.slice(0, -1), 10);
      const unit = shorthand.slice(-1);

      switch (unit) {
        case "Y":
          startDate.setFullYear(today.getFullYear() - value);
          break;
        case "M":
          startDate.setMonth(today.getMonth() - value);
          break;
        case "D":
          startDate.setDate(today.getDate() - value);
          break;
      }
    }
    return startDate.toISOString().split("T")[0];
  }

  const handleSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>,
    value: null | string = null,
  ) => {
    event.preventDefault();
    let dateRange: DateRange;

    if (value) {
      dateRange = strToDateRange(value);
    } else if (defaultDate) {
      dateRange = strToDateRange(processDefaultDate(defaultDate));
    } else {
      throw new Error("Invalid date range");
    }
    submitDateRange(dateRange.start_date, dateRange.end_date);
  };

  const getYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toISOString().split("T")[0];
  };

  const strToDateRange = (date: string) => {
    const shorthandRegex = /^(YTD|\d+Y|\d+M|\d+D)$/;
    if (shorthandRegex.test(date)) {
      const startDate = calculateStartDateFromShorthand(date);
      const endDate = getYesterday();
      console.log(endDate);
      return { start_date: startDate, end_date: endDate };
    }

    const dates = date.split("::").map((d) => d.trim());
    let startDate = dates[0];
    let endDate = dates[1] || "";

    const getLastDayOfPrevious = (year: string, month?: string) => {
      if (month) {
        const date = new Date(parseInt(year), parseInt(month), 0);
        return date.toISOString().split("T")[0];
      } else {
        return `${year}-01-01`;
      }
    };

    if (!shorthandRegex.test(startDate)) {
      const startParts = startDate.split("-");
      if (startParts.length === 1) {
        startDate = getLastDayOfPrevious(startParts[0]);
      } else if (startParts.length === 2) {
        startDate = getLastDayOfPrevious(startParts[0], startParts[1]);
      }
    }

    if (endDate && !shorthandRegex.test(endDate)) {
      const endParts = endDate.split("-");
      if (endParts.length === 1) {
        endDate = getLastDayOfPrevious(endParts[0]);
      } else if (endParts.length === 2) {
        endDate = getLastDayOfPrevious(endParts[0], endParts[1]);
      }
    } else if (!endDate) {
      endDate = getYesterday();
    }

    const range: DateRange = { start_date: startDate, end_date: endDate };

    return range;
  };

  const validateDate = (input: string): boolean => {
    const regex =
      /^(?:(?:YTD)|(?:\d{4}(?:-\d{2}(?:-\d{2})?)?)|(?:\d+Y|\d+M|\d+D))(?:::(?:(?:\d{4}(?:-\d{2}(?:-\d{2})?)?)|(?:\d+Y|\d+M|\d+D)))?$/;
    return regex.test(input);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (validateDate(value)) {
      setError(false);
    } else {
      setError(true);
    }
  };
  if (variant === "button") {
    return (
      <>
        <Button
          sx={{
            lineHeight: "unset",
            minWidth: "30px",
            fontSize: 11,
            bgcolor: "#312b34",
            padding: "1px 10px",
          }}
          onClick={(e) => handleSubmit(e)}
          variant="text"
        >
          {label}
        </Button>
      </>
    );
  } else {
    return (
      <form
        onSubmit={(e) => handleSubmit(e, inputValue)}
        style={{ width: "inherit" }}
      >
        <Box gap={1} display="flex" width="100%">
          <RAMTextField
            required
            fullWidth
            error={error}
            label="Date"
            placeholder="Enter date in yyyy-mm-dd::yyyy-mm-dd format"
            value={inputValue}
            onChange={handleDateChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    sx={{ height: "30px", px: 2, width: "25px" }}
                    disabled={error}
                  >
                    <CheckIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </form>
    );
  }
};

export default EnterDate;
