import React from "react";
import { usePlotContext } from "../../../../../contexts/PlotContext";
import {
  AxisTickAngle,
  BaseSettings,
} from "../../../../../types/plot_settings/plot_settings_interfaces";
import { TextInput } from "../setting_inputs/TextInput";
import { Box, Divider, Stack, SxProps } from "@mui/material";
import { SelectInput } from "../setting_inputs/SelectInput";
import {
  AXIS_TICK_ANGLE_OPTIONS,
  AXIS_TICK_FORMATS,
  DATE_TIME_FORMAT_OPTIONS,
} from "../../../../../types/plot_settings/plot_settings_info";
import { NumberInput } from "../setting_inputs/NumberInput";
import ColorInput from "../setting_inputs/ColorInput";
import { defaultBaseSettings } from "../../../../../types/plot_settings/default_plot_settings";

const AxesSettings: React.FC = () => {
  const { plot } = usePlotContext();

  const isValidSettings = (settings: any): settings is BaseSettings => {
    const baseSettingsKeys = Object.keys(settings) as Array<keyof BaseSettings>;
    return baseSettingsKeys.every((key) => key in settings);
  };

  const settings: BaseSettings = plot.config.settings;
  if (!isValidSettings(settings)) {
    throw new Error("Settings are invalid");
  }

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <TextInput
          label="Y Axis Label"
          value={settings.y_label || ""}
          onChange={(value) => plot.setSettings({ y_label: value })}
        />
        <Box display="flex" gap={1}>
          <NumberInput
            label="Label Size"
            value={settings.y_label_fontsize || 12}
            onChange={(value) => plot.setSettings({ y_label_fontsize: value })}
          />
          <SelectInput
            label="Tick Angle"
            value={settings.y_axis_tick_angle}
            onChange={(value) => plot.setSettings({ y_axis_tick_angle: value })}
            options={AXIS_TICK_ANGLE_OPTIONS}
          />
          <SelectInput
            label="Tick Format"
            value={settings.y_axis_tick_format || null}
            onChange={(value) =>
              plot.setSettings({ y_axis_tick_format: value })
            }
            options={AXIS_TICK_FORMATS}
          />
        </Box>
      </Stack>
      <Divider />
      <Stack gap={2}>
        <TextInput
          label="X Axis Label"
          value={settings.x_label || ""}
          onChange={(value) => plot.setSettings({ x_label: value })}
        />
        <Box display="flex" gap={1}>
          <NumberInput
            label="Label Size"
            value={settings.x_label_fontsize || 12}
            onChange={(value) => plot.setSettings({ x_label_fontsize: value })}
          />
          <SelectInput
            label="Tick Angle"
            value={settings.x_axis_tick_angle}
            onChange={(value: AxisTickAngle) =>
              plot.setSettings({ x_axis_tick_angle: value })
            }
            options={AXIS_TICK_ANGLE_OPTIONS}
          />
          <SelectInput
            label="Tick Format"
            value={settings.x_axis_tick_format || null}
            onChange={(value) =>
              plot.setSettings({ x_axis_tick_format: value })
            }
            options={DATE_TIME_FORMAT_OPTIONS}
          />
        </Box>
      </Stack>
      <Divider />
      <Box display="flex" gap={1} alignItems="center">
        <NumberInput
          label="Axis Thickness"
          value={settings.axis_thickness || defaultBaseSettings.axis_thickness}
          onChange={(value) => plot.setSettings({ axis_thickness: value })}
        />
        <ColorInput
          label="Axes Color"
          value={settings.axis_color}
          onChange={(value) => plot.setSettings({ axis_color: value })}
        />
        <NumberInput
          label="Tick Size"
          value={settings.x_axis_tick_fontsize || 12}
          onChange={(value) =>
            plot.setSettings({
              x_axis_tick_fontsize: value,
              y_axis_tick_fontsize: value,
            })
          }
        />
      </Box>
    </Stack>
  );
};
AxesSettings.displayName = "Axes";
export default AxesSettings;
